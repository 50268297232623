.container {
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(180deg, #5ceec7 0%, #4db8ff 100%);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .login-container {
    text-align: center;
    width: 45vw;
    height: 100vh;
    padding: 50px 80px;
    background-color: white;
    backdrop-filter: blur(50px);
  }
  .login-container img {
    width: 150px;
    height: 150px;
  }
  .login-container button {
    padding: 15px;
    margin-top: 10px;
  }
  .login-container h2 {
    font-size: 28px;
    margin: 0px;
    padding: 0px;
  }
  @media screen and (max-width: 960px) {
    .login-container {
      width: 50vw;
      padding: 30px;
    }
  }
  @media screen and (max-width: 768px) {
    .login-container {
      width: 90vw;
      padding: 30px;
    }
  }
  